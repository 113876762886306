import { Input, Select } from "antd"
import { FC, useState } from "react"
import { SvgIcon } from "../SvgIcon"

const Search: FC<{
  searchPlaceholder: string
  searchOptions: Maybe<Maybe<TextFragment>[]>
}> = ({ searchPlaceholder, searchOptions }) => {
  const [inputValue, setInputValue] = useState("")
  const [option, setOption] = useState(!!searchOptions?.length && searchOptions[0]?.text ? searchOptions[0]?.text : "")
  return (
    <div className={`search ${!searchOptions?.length && "onlySearchInput"}`}>
      <Input
        placeholder={searchPlaceholder}
        value={inputValue}
        onChange={e => setInputValue(e.target.value)}
        prefix={<SvgIcon type={"Search"} />}
      />
      {!!searchOptions?.length && (
        <Select defaultValue={option} onChange={e => setOption(e)}>
          {searchOptions?.map((optionValue, index) => (
            <Select.Option key={index} value={optionValue?.text}>
              {optionValue?.text}
            </Select.Option>
          ))}
        </Select>
      )}
    </div>
  )
}

export { Search }
