import { FC, useState, memo } from "react"
import { CardFullWidthOpenTextLink } from "../../ui/CardFullWidthOpenTextLink"
import "./style.less"

const CardsFullWidthOpenTextLink: FC<{
  section: SectionFragment
  cards: Maybe<Maybe<CardFragment>[]>
  links: Maybe<Maybe<LinkFragment>[]>
}> = memo(({ section, cards, links }) => {
  const [open, setOpen] = useState(() => cards?.map(card => card?.isOpen) || [false])

  const hasTitle = section?.title;
  const hasCards = !!cards?.length;

  return (
    <section className={"wrapperCardsFullWidthOpenTextLink containerWrapper"}>
      <div className={"container"}>
        {hasTitle && (
          <h2 className={"title"}>
            {section.title}
            {!section?.title?.endsWith('?') && <span className={"dot-span"}></span>}
          </h2>
        )}
        {hasCards && (
          <ul>
            {cards.map((card, index) => (
              <CardFullWidthOpenTextLink
                key={index}
                card={card}
                link={links?.[index]}
                index={index}
                open={open}
                setOpen={setOpen}
              />
            ))}
          </ul>
        )}
      </div>
    </section>
  )
})

export { CardsFullWidthOpenTextLink }
