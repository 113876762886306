import React, { Dispatch, ReactElement, ReactNode, SetStateAction } from "react"
import { NavLink } from "react-router-dom"
import { Link as LinkScroll } from "react-scroll"
import { Img } from "../Img"
import { Link } from "../Link"
import { SvgIcon } from "../SvgIcon"
import { homePageLink } from "../../constants"
import { truncateWithEllipsis } from "../../utils"

export const generateIndex = (index: number): string => `${index + 1}`
const list = (list: string) => <ul className={"cardList"}>{generateListItem(list, "avgTextLight")}</ul>
export const generateListItem = (list: string, className: string): ReactNode[] =>
  list
    ?.split("\n")
    ?.filter(elem => !!elem)
    .map((li, index) => (
      <li key={index} className={className}>
        {li.slice(1)}
      </li>
    ))
export const generateLinks = (
  info: Array<Maybe<LinkFragment>>,
  className: string,
  isStaticArrow?: boolean
): ReactElement[] =>
  info?.map((link, index) => {
    const emptyLink = !link?.url || link?.url === homePageLink;
    const url = link?.url ? link.url : "#";
    return (
      <li key={index} className={"li"}>
        {link?.title === "Schedule a Demo" || link?.isMeeting ? (
          <Link link={link} className={`link ${className} calendarLink`} calendar />
        ) : link?.file?.data?.attributes?.url ? (
          <Link link={link} className={`link ${className}`} target={"_blank"} />
        ) : (
          <NavLink to={url} className={`link ${className} ${emptyLink && "emptyLink"}`}>
            {link?.title}
            {isStaticArrow ? <SvgIcon type={"ArrowLink"} className={"icon"} /> : <span className={"arrowIcon"} />}
          </NavLink>
        )}
      </li>
    )
  })

  export const generateCardsFullWidth = (
    info: Array<Maybe<CardFragment>>,
    isStaticArrow?: boolean,
    titleLink?: boolean,
    setOpenKeys?: Dispatch<SetStateAction<string[]>>
  ): ReactElement[] =>
    info?.map((card, index) => {
      const title = card?.title && <h4 className={"cardTitle h4BigSecondBold"}>{card?.title}</h4>
      const text = <span className={"avgTextLight cardText"}>{card?.text}</span>
      const isHomePageLink = card?.url === homePageLink
      const link = isHomePageLink ? "" : card?.url ?? ""
  
      // Condition to check if NavLink should not be used
      const shouldRenderSpan = isHomePageLink || (!card?.url && !card?.file?.data?.attributes?.url)
  
      return (
        <li
          key={index}
          onClick={() => {
            if (titleLink && setOpenKeys) {
              setOpenKeys([])
            }
          }}
        >
          {shouldRenderSpan ? (
            <span className={`card notLink`}>
              <div className={"wrapper"}>
                {card?.title && card?.icon?.data?.attributes?.url ? (
                  <div className={"wrapperTitle"}>
                    <Img img={card?.icon} className={"imgTitle radius"} />
                    {title}
                  </div>
                ) : (
                  title
                )}
                {card?.text && card?.list ? (
                  <div className={"textWrapper"}>
                    {text}
                    {list(card?.list)}
                  </div>
                ) : (
                  <React.Fragment>
                    {card?.text && <div className={"textWrapper"}>{text}</div>}
                    {card?.list && <div className={"textWrapper"}>{list(card?.list)}</div>}
                  </React.Fragment>
                )}
                {isStaticArrow ? <SvgIcon type={"ArrowLink"} className={"icon"} /> : <span className={"arrowIcon"} />}
              </div>
              {card?.sup && <span className={"bigLinkThird sup"}>{card?.sup}</span>}
            </span>
          ) : (
            <NavLink
              className={`card`}
              to={titleLink ? link : card?.file?.data?.attributes?.url || link}
            >
              {/* <span className={"avgTextSm index"}>{generateIndex(index)}</span> */}
              <div className={"wrapper"}>
                {card?.title && card?.icon?.data?.attributes?.url ? (
                  <div className={"wrapperTitle"}>
                    <Img img={card?.icon} className={"imgTitle radius"} />
                    {title}
                  </div>
                ) : (
                  title
                )}
                {card?.text && card?.list ? (
                  <div className={"textWrapper"}>
                    {text}
                    {list(card?.list)}
                  </div>
                ) : (
                  <React.Fragment>
                    {card?.text && <div className={"textWrapper"}>{text}</div>}
                    {card?.list && <div className={"textWrapper"}>{list(card?.list)}</div>}
                  </React.Fragment>
                )}
                {isStaticArrow ? <SvgIcon type={"ArrowLink"} className={"icon"} /> : <span className={"arrowIcon"} />}
              </div>
              {card?.sup && <span className={"bigLinkThird sup"}>{card?.sup}</span>}
            </NavLink>
          )}
        </li>
      )
    })
  

export const generateCardLink = (info: Maybe<CardFragment>[], isLinkString?: boolean): ReactElement[] =>
  info?.map((card, index) =>
    isLinkString ? (
      <article key={index} className={"card linkString"}>
        <div className={"imgWrapper"}>
          <Img img={card?.icon} className={"img"} />
        </div>
        <div className={"textWrapper"}>
          <Link link={{ url: card?.url, title: card?.sup }} className={"bigLinkThird sup linkBtn"} arrowIcon />
        </div>
      </article>
    ) : (
      <article
        key={index}
        className={`card ${
          (card?.url === homePageLink || (!card?.url && !card?.file?.data?.attributes?.url)) && "notLink"
        }`}
      >
        <NavLink
          to={
            card?.file?.data?.attributes?.url
              ? card?.file?.data?.attributes?.url
              : card?.url === homePageLink
              ? ""
              : card?.url ?? ""
          }
        >
          <div className={"imgWrapper"}>
            <Img img={card?.icon} className={"img"} />
            <SvgIcon type={"ArrowLink"} className={"icon"} />
          </div>
          <div className={"textWrapper"}>
            {card?.text && <p className={"avgTextBookMediumSecond cardText"}>{card?.text}</p>}
            {card?.title && <h4 className={"h4BigSecondBold cardTitle"}>{truncateWithEllipsis(card?.title, 50)}</h4>}
            {card?.sup && <span className={"mdTextSecond sup"}>{card?.sup}</span>}
          </div>
        </NavLink>
      </article>
    )
  )

export const searchLinkInTextAndChangeText = (string: string): string | ReactNode => {
  const linkParentheses = string.match(/\[.*?]/g)
  if (!linkParentheses) {
    return string
  }
  const link = linkParentheses.map(item => item.slice(1, -1))
  const arr = string.replace(linkParentheses[0], "[").replace(linkParentheses[1], "[").split("[[")
  return (
    <React.Fragment>
      {arr[0]}
      <Link link={{ url: link[1], title: link[0] }} className={"link"} />
      {arr[1]}
    </React.Fragment>
  )
}
export const generateCardEntry = (
  index: number,
  cardTitle: Maybe<string>,
  text: Maybe<string>,
  sup: Maybe<string>,
  isTablet: boolean
): ReactElement => (
  <React.Fragment>
    <span className={"index mdTextThird"}>{`${index + 1 <= 9 ? 0 : ""}${index + 1}`}</span>
    {cardTitle && <h4 className={"h4BigSecondBold cardTitle"}>{cardTitle}</h4>}
    {text && <p className={"cardText avgTextLight"}>{text}</p>}
    {text && (
      <LinkScroll to={cardTitle ?? ""} offset={isTablet ? -100 : 0} className={"link linkBtn bigLinkThird"}>
        <span>{sup}</span>
        <span className={"arrowIcon"} />
      </LinkScroll>
    )}
    <SvgIcon type={"Plus"} className={"icon"} />
  </React.Fragment>
)

export const generateParagraphs = (text: Maybe<string>): ReactElement => (
  <React.Fragment>
    {text?.indexOf("\n") === -1 ? (
      <h4 className={"h4BigSecondBold text lightWeight"}>{text}</h4>
    ) : (
      text?.split("\n").map((paragraph, index) => (
        <h4 key={index} className={"h4BigSecondBold text lightWeight"}>
          {paragraph}
        </h4>
      ))
    )}
  </React.Fragment>
)
export const generateCardCareerInfo = (array: [Maybe<string | number>, Maybe<string>][]): ReactNode =>
  !!array?.length &&
  array?.map(
    (data, index) =>
      data[0] && (
        <h4 key={index} className={"h4SmBold cardInfo"}>
          {data[1]}:<span className={"h4SmMediumBook value"}>{data[0]}</span>
        </h4>
      )
  )
