import type { CollapseProps } from "antd"
import { Collapse } from "antd"
import { FC, memo } from "react"
import { CardOpeningIcon } from "../../ui/CardOpeningIcon"
import { Img } from "../../ui/Img"
import { Link } from "../../ui/Link"
import "./style.less"
import { useLocation, useNavigate } from "react-router-dom"
import Button from "../../ui/Buttons"
import { pathIndustries } from "../../constants"
const CardsOpeningIcon: FC<SectionCardsFragment> = memo(({
  title,
  text,
  cards = [],
  list,
  backgroundColorCards,
  img,
  links = [],
  sup,
  sectionId
}) => {
  const items: CollapseProps["items"] = cards?.map((card, index) => ({
    key: index,
    label: <CardOpeningIcon key={index} index={index} card={card} />,
  })) || []

  const navigate = useNavigate()
  const {pathname} = useLocation()
  const defaultActiveKey = cards
    ?.map((card, index) => (card?.isOpen ? index : undefined))
    ?.filter(key => key !== undefined)
    ?.toString()

  return (
    <section className={"wrapperCardsOpeningIcon containerWrapper"}>
      <span id={sectionId ?? ""} className={"anchor"} />
      <div className={`${backgroundColorCards} container`}>
        <div className={"containerWrapper"}>
          <div>
            {title && <h2 className={"sectionTitle"}>{title}<span className={"dot-span"}></span></h2>}
            {text && <h6 className={"subtitle lightWeight"}>{text}</h6>}
          </div>
          <div>
            {pathname === '/' && <Button variant={"arrow-animate"} onClick={() => navigate(`/${pathIndustries}`)}>
              See all industries <span className={"icon-right-top-arrow"}></span>
            </Button>}
          </div>
        </div>

        <Collapse
          accordion
          items={items}
          className={"cards"}
          defaultActiveKey={defaultActiveKey}
        />
        {img?.data?.attributes?.url && !!links?.length && sup && (
          <div className={"section"}>
            <Img img={img} className={"img radius"} />
            <div className={"article"}>
              {list && <h6 className={"h6Bold"}>{list}</h6>}
              {sup && <p className={"avgTextSmSm sup"}>{sup}</p>}
              <Link link={links[0]} className={"bigLinkThird linkBtn radius"} arrowIcon />
            </div>
          </div>
        )}
      </div>
    </section>
  )
})

export { CardsOpeningIcon }
