import React, { useEffect, useState } from "react";
import { XPContainer } from "./HeaderStyle";
import { Link } from "react-router-dom";
import HeaderLogo from "./Logo";
import { SvgIcon } from "../ui/SvgIcon";
import DropLayout from "./DropDownLayout";
import { LinksMain, MainHeaderProps } from "./GlobalTypes";
import { motion } from 'framer-motion';

const MainHeader: React.FC<MainHeaderProps> = ({ mainHeader, logo, menuIcons, menuCtas }) => {
    const [isOpen, setIsOpen] = useState(false);
    const [activeDropdownId, setActiveDropdownId] = useState<string | null>(null);
    const [mainMenuList, setMainMenuList] = useState<LinksMain[]>([]);
    const [isFixed, setIsFixed] = useState(false);
    const [dropdownTimer, setDropdownTimer] = useState<NodeJS.Timeout | null>(null);

    const toggleValueEnter = (id: string) => {
        // Start a timer that will open the dropdown after 200 ms
        const timer = setTimeout(() => {
            setIsOpen(true);
            setActiveDropdownId(id);
        }, 250);

        setDropdownTimer(timer); // Save the timer to state
    };

    const toggleValueLeave = () => {
        // If the mouse leaves, clear the timer and close the dropdown immediately
        if (dropdownTimer) {
            clearTimeout(dropdownTimer);
            setDropdownTimer(null); // Clear the timer from state
        }
        setIsOpen(false);
        setActiveDropdownId(null);
    };

    useEffect(() => {
        if (mainHeader && mainHeader.isActive) {
            setMainMenuList(mainHeader.LinksMain || []);
        }
    }, [mainHeader]);

    useEffect(() => {
        const handlePosition = () => {
            if (window.scrollY > 200) {
                setIsFixed(true);
            } else {
                setIsFixed(false);
            }
        };

        window.addEventListener("scroll", handlePosition);
        return () => window.removeEventListener("scroll", handlePosition);
    }, []);

    // useEffect(() => {
    //     if (isOpen) {
    //         document.querySelector(".wrapperMainContent")?.classList.add("blur-content");
    //     } else {
    //         document.querySelector(".wrapperMainContent")?.classList.remove("blur-content");
    //     }

    //     return () => {
    //         document.querySelector(".wrapperMainContent")?.classList.remove("blur-content");
    //     };
    // }, [isOpen]);

    const handleLinkClick = () => {
        setIsOpen(false);
        setActiveDropdownId(null);
    };

    const isActiveMenu = (link: LinksMain) => {
        if (!link.Redirect || link.Redirect.trim() === "") return false;

        if (location.pathname === link.Redirect) {
            return true;
        }

        if (location.pathname.startsWith(`${link.Redirect}/`) && link.Redirect !== "/") {
            return true;
        }

        if (location.pathname === "/" && (link.Redirect === "/" || link.Redirect === "/home")) {
            return true;
        }

        if (link.drop_menu_lists?.data?.some((subLink) => subLink.attributes?.Redirect &&
            location.pathname === subLink.attributes.Redirect && subLink.attributes.Redirect !== "/")) {
            return true;
        }

        if (link.drop_menu_list_secondaries?.data?.some((subLink) => subLink.attributes?.Redirect &&
            location.pathname === subLink.attributes.Redirect && subLink.attributes.Redirect !== "/")) {
            return true;
        }

        return false;
    };

    const dropdownVariants = {
        open: {
            opacity: 1,
            height: "auto",
            transition: {
                duration: 0.2, // Set the duration for smoothness
                ease: [0.4, 0.0, 0.2, 1], // Custom easing for smoother transition
            },
        },
        closed: {
            opacity: 0,
            height: 0,
            transition: {
                duration: 0.3, // Set the duration for smoothness
                ease: [0.4, 0.0, 0.2, 1], // Custom easing for smoother transition
            },
        },
    };
    return (
        <section className={`main-header-section ${isFixed ? "fixed-header" : ""}`}>
            <XPContainer className={"container"}>
                <div className={"main-header-container"}>
                    <div className={"main-logo"}>
                        <HeaderLogo logo={logo} />
                    </div>
                    <div className={"main-left-container"}>
                        <ul className={"nav-list"}>
                            {mainMenuList.filter(link => link?.isActive).map(link => (
                                <li
                                    key={link.id}
                                    className={`nav-items ${isActiveMenu(link) ? "active" : ""}`}
                                    onMouseEnter={() => link?.isDropDown && toggleValueEnter(link?.id)}
                                    onMouseLeave={toggleValueLeave}
                                    onClick={handleLinkClick}
                                >
                                    <Link className={"main-nav-link"} to={link?.Redirect} onClick={handleLinkClick}>
                                        {link?.Icon?.data?.attributes?.url && (
                                            <img
                                                className={"main-nav-icon"}
                                                src={link?.Icon?.data?.attributes?.url}
                                                alt={link?.Title}
                                            />
                                        )}
                                        <span className={"main-nav-items"}>
                                            <span className={"main-nav-items-text"}>{link?.Title}</span>
                                            {link?.isDropDown && (
                                                <span className={"dropIcon"}>
                                                    <SvgIcon type={"Chevron"} />
                                                </span>
                                            )}
                                        </span>
                                    </Link>
                                    {link?.isDropDown && isOpen && activeDropdownId === link?.id && (
                                        <motion.div
                                        onMouseEnter={() => setIsOpen(true)}
                                        onMouseLeave={toggleValueLeave}
                                        initial={"closed"}
                                        animate={isOpen ? "open" : "closed"}
                                        variants={dropdownVariants}
                                        className={"dropDown-items"}
                                        >
                                            <DropLayout
                                                toggleValue={isOpen}
                                                dropMenuItems={link.drop_menu_lists.data}
                                                dropMenuCard={link.drop_cards}
                                                dropDownTitle={link?.Title}
                                                dropMenuSecondaryItems={link?.drop_menu_list_secondaries?.data}
                                            />
                                        </motion.div>
                                    )}
                                </li>
                            ))}
                        </ul>

                        {(menuIcons?.isActive || menuCtas?.isActive) && (
                            <div className={"main-header-actions"}>
                                {menuIcons?.isActive && menuIcons?.menuIcon.filter(link => link.isActive).map(link => (
                                    <Link key={link.id} to={link.Redirect} className={"cursor-pointer"}>
                                        <img
                                            src={link?.Icon?.data?.attributes?.url}
                                            alt={"Menu Icon"}
                                        />
                                    </Link>
                                ))}
                                {menuCtas?.isActive && menuCtas.menuCTA.filter(link => link.isActive).map(link => (
                                    link.Title ? (
                                        <button
                                            aria-label={link.Title}
                                            key={link.id}
                                        >
                                            <img src={link.CTAImage.data.attributes.url} alt={link.Title} />
                                            <Link to={link.Redirect || ""}>{link.Title}</Link>
                                        </button>
                                    ) : (
                                        <button
                                            key={link.id}
                                            aria-label={link.Title}
                                        >
                                            <Link to={link.Redirect || ""}><img src={link.CTAImage.data.attributes.url} alt={link.Title} /></Link>
                                        </button>
                                    )

                                ))}
                            </div>
                        )}
                    </div>
                </div>
            </XPContainer>
        </section>
    );
};

export default MainHeader;
