import React, { FC, useState, useMemo } from "react"
import { useLocation } from "react-router-dom"
import { Img } from "../../ui/Img"
import { Search } from "../../ui/Search"
import { generateLinks, generateListItem } from "../../ui/generateFunctions"
import { Link } from "../../ui/Link"
import { SvgIcon } from "../../ui/SvgIcon"
import XcelButton from "../../ui/Button"
import { useMediaQuery } from "react-responsive"
import "./style.less"

type DownloadURL = {
  downloadURL?: string
}

type pagekey = {
  keyProp?: any
}

type HeroProps = HeroFragment & DownloadURL & pagekey

const Hero: FC<HeroProps> = React.memo(({
  sup,
  title,
  underlineLinks,
  btnLinks,
  img,
  list,
  searchPlaceholder,
  searchOptions,
  keyProp,
  backgroundImage,
  gradientBackground,
  bckColor
}) => {
  const { pathname } = useLocation()
  const [open, setOpen] = useState(false)
  const currentCategory = useMemo(() => pathname.split("/")[1], [pathname])
  const isMobile = useMediaQuery({ query: '(max-width: 575px)' });

  const buttonConfigs = useMemo(() => [
    {
      color: '#2251FF',
      width: 'max-content',
      hoverFontColor: '#FAFAFA',
      backgroundColor: '#FAFAFA',
      borderColor: 'transparent',
      hoverBorderColor: 'transparent',
      focusColor: '#E5491F',
      hoverColor: '#E5491F',
      clickColor: '#E5491F',
      iconFillColor: 'none',
      hoverIconFillColor: 'none',
      iconStrokeColor: '#2251FF',
      hoverIconStrokeColor: '#fff',
    },
    {
      color: '#000',
      width: 'max-content',
      hoverFontColor: '#fff',
      backgroundColor: '#fff',
      borderColor: 'transparent',
      hoverBorderColor: 'transparent',
      focusColor: '#131313',
      hoverColor: '#131313',
      clickColor: '#131313',
      iconFillColor: 'none',
      hoverIconFillColor: 'none',
      iconStrokeColor: '#131313',
      hoverIconStrokeColor: '#fff',
    }
  ], []);

  return (
    <section className={"wrapperSectionHero"}
      style={{
        backgroundColor: bckColor || 'transparent',
        backgroundImage: backgroundImage?.data?.attributes?.url ? `url(${backgroundImage.data.attributes.url})` : `linear-gradient(${gradientBackground})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
      }}
    >
      <div className={"container"}>
        {pathname.split("/").length > 2 && (
          <div className={"breadcrumb"}>
            <SvgIcon type={"Chevron"} style={{ fontSize: "12px" }} />
            <Link
              link={{
                url: `/${currentCategory}`,
                title: currentCategory === 'consulting-services' ? 'services' : currentCategory,
              }}
              className={"breadcrumb-link"}
            />
            <span className={"breadcrumb-separator"}>|</span>
            <span className={"breadcrumb-key"}>{keyProp}</span>
          </div>
        )}
        <div className={"wrapperBckImgAbs"}>
          <div className={"textRevert"}>
            {sup && <h4 className={"sup"}
              dangerouslySetInnerHTML={{ __html: sup }}
            />
            }
            {title && (
              <h1 className={"title"}>
                <span dangerouslySetInnerHTML={{ __html: title }} />
                <span className={"dot-span"}></span>
              </h1>
            )}
            {list && <ul className={"list"}>{generateListItem(list, "avgTextBookLight")}</ul>}
          </div>
          {!!underlineLinks?.length && (
            <ul className={"underlineLink"}>{generateLinks(underlineLinks, "h4BigSecondBold")}</ul>
          )}
          {searchPlaceholder && <Search searchPlaceholder={searchPlaceholder} searchOptions={searchOptions} />}
          {(!!btnLinks?.length) && (
            <div className={"wrapperLink"}>
              {btnLinks.map((item, index) => (
                <XcelButton
                  key={index}
                  content={item?.title}
                  href={item?.url}
                  type={"button"}
                  iconUrl={item?.icon?.data?.attributes?.url}
                  cssIndex={index}
                  download={item?.file?.data?.attributes?.url}
                  buttonsCSS={buttonConfigs} />
              ))}
            </div>
          )}
        </div>
      </div>
      {!isMobile && (
        <div className={"backgroundImg"}>
          <Img img={img} className={""} />
        </div>
      )}
    </section>
  )
})

export { Hero }
