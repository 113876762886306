import { Button, Col, Row } from "antd"
import { Dispatch, FC, SetStateAction, useRef } from "react"
import ReactMarkdown from "react-markdown"
import remarkGfm from "remark-gfm"
import { SvgIcon } from "../SvgIcon"
import XcelButton from "../Button"

const CardFullWidthOpenTextLink: FC<{
  card: Maybe<CardFragment>
  link: Maybe<LinkFragment>
  index: number
  open: Maybe<boolean>[]
  setOpen: Dispatch<SetStateAction<Maybe<boolean>[]>>
}> = ({ card, link, index, open, setOpen }) => {
  const isOpening = open[index]
  const cardRef = useRef<HTMLLIElement>(null)

  const buttonConfigs = [{
    color: '#fff',
    width: 'max-content',
    hoverFontColor: '#fff',
    backgroundColor: '#2251FF',
    borderColor: 'none',
    hoverBorderColor: 'none',
    focusColor: '#2251FF',
    hoverColor: '#131313',
    clickColor: '#2251FF',
    iconFillColor: 'none',
    hoverIconFillColor: '',
    iconStrokeColor: '',
    hoverIconStrokeColor: '',
  }
  ]
  return (
    <li
      ref={cardRef}
      className={`card ${isOpening ? "open" : "close"}`}
      onClick={() => {
        // setOpen(open?.map((item, id) => (id === index ? !item : false)))
        // if (cardRef.current) {
        //   setTimeout(() => {
        //     if (cardRef?.current) {
        //       window.scrollTo({
        //         top: cardRef.current.offsetTop - 0.2 * window.innerHeight || document.documentElement.clientHeight,
        //         behavior: "smooth",
        //       })
        //     }
        //   }, 0)
        // }
        setOpen(open.map((item, id) => (id === index ? !item : item)));
      }}
    >
      {card?.title && <h3 className={"cardTitle h4BigSecondBold"}>{card?.title}</h3>}
      {card?.text && isOpening && <p className={"cardText"}> {card?.text}</p>}
      {isOpening && (
        // <Button href={link?.url} className={"linkButton"} >
        //   <Row gutter={8}>
        //     <Col>Learn more</Col>
        //     {/* <Col>
        //       <SvgIcon type={"ArrowLink"} className={"arrowLinkIcon"} />
        //     </Col> */}
        //   </Row>
        // </Button>
        link?.url && (
          <div className={"FAQLinkButton"}>
            <XcelButton content={"Learn more"} href={link?.url} type={"button"} buttonsCSS={buttonConfigs} />
          </div>
        )
      )}
      {card?.list && isOpening && (
        <ReactMarkdown remarkPlugins={[remarkGfm]} className={"list"}>
          {card?.list}
        </ReactMarkdown>
      )}

      <SvgIcon type={"Plus"} className={"icon"} />
    </li>
  )
}

export { CardFullWidthOpenTextLink }
