import { Form, Formik, FormikHelpers } from "formik"
import React, { FC, useCallback } from "react"
import { useCreateFormMutation, useGetFormEmailLazyQuery } from "../../../graphql"
import { useGeneralContext } from "../../context";
import { Input } from "../../ui/Form";
import { ValuesFormChallenge, formChallengeSchema, initialStateFormChallenge } from "../../utils";
import "./style.less";
import XcelButton from "../../ui/Button";

const FormChallenge: FC<{ data: Maybe<SectionInputBtnFragment>; closeModal?: () => void }> = React.memo(({ data, closeModal }) => {
  const [createForm] = useCreateFormMutation()
  const { messageApi, openNotification } = useGeneralContext()
  const [getFormEmails] = useGetFormEmailLazyQuery();  

  const onSubmit = useCallback(async (values: ValuesFormChallenge, formikBag: FormikHelpers<ValuesFormChallenge>) => {
    try {
      const { data: formEmailsData } = await getFormEmails();
      const formEmails = formEmailsData?.forms?.data?.map((entry: any) => entry.attributes.email) || [];

      if (formEmails.includes(values.email)) {
        openNotification(
          "Email Already Exists",
          "This email has already been submitted. Please use a different email.",
          "top"
        );
        return;
      }

      await createForm({
        variables: {
          data: {
            name: values.name,
            email: values.email,
            challenge: values.challenge,
            company: values.company,
            phone: values.phone ? Number(values.phone) : undefined,
            receiveUpdates: values.isAgree,
          },
        },
      });

      formikBag.resetForm();
      openNotification(
        "Form Submission Successful",
        "Your form was successfully submitted. We will get back to you within 48 hours.",
        "top"
      );
      if (closeModal) closeModal();
    } catch (error) {
      console.error("Error submitting form:", error);
      openNotification("Submission Failed", "There was an error submitting your form. Please try again.", "top");
    }
  }, [createForm, getFormEmails, openNotification, closeModal]);

  const buttonConfigs = [{
    color: '#2251FF',
    width: '100%',
    hoverFontColor: '#fff',
    backgroundColor: '#fff',
    borderColor: 'none',
    hoverBorderColor: 'none',
    focusColor: '#131313',
    hoverColor: '#131313',
    clickColor: '#fff',
    iconFillColor: 'none',
    hoverIconFillColor:'', 
    iconStrokeColor:'',
    hoverIconStrokeColor: '',
  }];

  return (
    <section className={"wrapperFormChallenge containerWrapper"}>
      <span id={data?.title ?? ""} className={"anchor"} />
      <div className={"container"}>
        <Formik
          initialValues={initialStateFormChallenge}
          onSubmit={onSubmit}
          validationSchema={formChallengeSchema}
          validateOnBlur={false}
        >
          <Form className={`form ${data?.backgroundColorArticle && data?.backgroundColorArticle}`}>
            {data?.title && <h2 className={"title"}>{data?.title}{!data?.title.endsWith('?') &&<span className={"dot-span"}></span>}</h2>}
            {data?.text && <h6 className={"subtitle lightWeight"}>{data?.text}</h6>}
            {!!data?.inputs?.length && (
              <div className={"wrapper"}>
                {data?.inputs?.map((input, index) => (
                  <Input
                    key={index}
                    input={input}
                    placeholderClassName={"placeholderLight"}
                    classNameLabel={"avgTextSmLight"}
                  />
                ))}
              </div>
            )}
            <XcelButton content={data?.sup || "Send"} type={"submit"} buttonsCSS={buttonConfigs}/>
          </Form>
        </Formik>
      </div>
    </section>
  )
});

export { FormChallenge }
