import React from "react";
import { DropCardProps } from "../GlobalTypes";
import { Link } from "react-router-dom";

const DropCard: React.FC<DropCardProps> = ({ drop_cards }) => {
    return (
        <React.Fragment>
            {drop_cards?.data?.map(item => {
                const { redirect, Banner, Title, Description, CardBTN } = item?.attributes || {};
                const cardContent = (
                    <div className={"drop-main-card-container"}>
                        <img
                            className={"drop-card-image"}
                            src={Banner?.data?.attributes?.url ?? ''}
                            alt={Title ?? ""}
                        />
                        <div className={"drop-card-content"}>
                            <span className={"drop-card-title"}>{Title}</span>
                            <p className={"drop-card-para"}>{Description}</p>
                            {CardBTN?.map(btn => (
                                btn?.Title && (
                                    <button key={btn.id} className={"drop-card-action"} aria-label={btn.Title}>
                                        {btn.Title}
                                        {btn.CTAImage?.data && (
                                            <img src={btn.CTAImage.data.attributes?.url ?? ''} alt={""} />
                                        )}
                                    </button>
                                )
                            ))}
                        </div>
                    </div>
                );

                return redirect ? (
                    <Link key={item.id} to={redirect}>
                        {cardContent}
                    </Link>
                ) : (
                    <React.Fragment key={item.id}>
                        {cardContent}
                    </React.Fragment>
                );
            })}
        </React.Fragment>
    );
};

export default DropCard;
