import React from 'react';
import "./style.less"

interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  size?: 'small' | 'medium' | 'large';
  variant?: 'primary' | 'secondary' | 'danger' | 'arrow-animate';
  children: React.ReactNode;
}

const Button: React.FC<ButtonProps> = ({
  size = 'medium',
  variant = 'primary',
  children,
  ...props
}) => {
  const getSizeClasses = () => {
    switch (size) {
      case 'small':
        return 'btn-sm';
      case 'large':
        return 'btn-lg';
      case 'medium':
      default:
        return 'btn-md';
    }
  };

  const getVariantClasses = () => {
    switch (variant) {
      case 'secondary':
        return 'btn-secondary';
      case 'arrow-animate':
        return 'arrow-animate';
      case 'primary':
      default:
        return 'btn-primary';
    }
  };

  const classes = `btn ${getSizeClasses()} ${getVariantClasses()}`;

  return (
    <button className={classes} {...props}>
      {children}
    </button>
  );
};

export default Button;
