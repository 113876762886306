import { FC, useEffect, useState } from "react"
import ReactMarkdown from "react-markdown"
import remarkGfm from "remark-gfm"
import { Img } from "../Img"
import { SvgIcon } from "../SvgIcon"
import { ArrowRightOutlined } from "@ant-design/icons"
import { generateIndex } from "../generateFunctions"

// Helper function to fetch SVG as text
// const fetchSvgContent = async (url: string) => {
//   const response = await fetch(url);
//   return response.text();
// };

const CardOpeningIcon: FC<{ index: number; card: Maybe<CardFragment> }> = ({ card, index }) => {
  const [svgContent, setSvgContent] = useState<string | null>(null);

  // Load SVG content if the icon URL is an SVG
  // useEffect(() => {
  //   if (card?.icon?.data?.attributes?.url?.endsWith(".svg")) {
  //     fetchSvgContent(card.icon.data.attributes.url).then(setSvgContent);
  //   }
  // }, [card?.icon?.data?.attributes?.url]);

  return (
    <li>
      <div className={"wrapper"}>
        <h4 className={"h4BigSecondBlack index"}>{generateIndex(index)}</h4>
        <SvgIcon type={"Plus"} className={"icon"} />
      </div>
      {
        card?.icon?.data?.attributes?.url && 
        <Img img={card?.icon} className={"iconMain"} />
      }

      {/* {card?.icon?.data?.attributes?.url?.endsWith(".svg") && svgContent ? (
        // Render inline SVG if it’s an SVG
        <div className={"iconMain svgContainer"} dangerouslySetInnerHTML={{ __html: svgContent }} />
      ) : (
        // Fallback to Img component for non-SVG images
        <Img img={card?.icon} className={"iconMain"} />
      )} */}
      <div className={"cardContent"}>
        {card?.title && <h4 className={"h4BigSecondBold title"}>{card?.title}</h4>}
        {card?.text && <p className={"text listItem"}>{card?.text} </p>}
        {card?.list && (
          <ReactMarkdown remarkPlugins={[remarkGfm]} className={"list text listItem avgText"}>
            {card?.list}
          </ReactMarkdown>
        )}
        {card?.url && (
          <a href={`${card?.url}`} className={"learnMoreCta"}>
            Learn more &#8594;
          </a>
        )}
      </div>
    </li>
  );
};

export { CardOpeningIcon };
